<template>
	<div class="desktop">
		<section-1 />
		<section-2 />
		<section-4 />
		<section-5 />
		<section-6 />
		<section-7 />
		<section-8 />
		<section-9 />
		<section-10 />
		<section-11 />
		<section-12 />
		<section-13 />
		<section-14 />
		<section-15 />
		<section-16 />
		<section-17 />
		<section-18 />
		<footer-tbf />
	</div>
</template>

<script>
	import Section1 from '../LandingDesktop/Section01'
	import Section2 from '../LandingDesktop/Section02'
	import Section4 from '../LandingDesktop/Section04'
	import Section5 from '../LandingDesktop/Section05'
	import Section6 from '../LandingDesktop/Section06'
	import Section7 from '../LandingDesktop/Section07'
	import Section8 from '../LandingDesktop/Section08'
	import Section9 from '../LandingDesktop/Section09'
	import Section10 from '../LandingDesktop/Section10'
	import Section11 from '../LandingDesktop/Section11'
	import Section12 from '../LandingDesktop/Section12'
	import Section13 from '../LandingDesktop/Section13'
	import Section14 from '../LandingDesktop/Section14'
	import Section15 from '../LandingDesktop/Section15'
	import Section16 from '../LandingDesktop/Section16'
	import Section17 from '../LandingDesktop/Section17'
	import Section18 from '../LandingDesktop/Section18'
	import FooterTbf from '../LandingDesktop/FooterTbf'

	export default {
		data() {
			return {
			};
		},
		components: {
			Section1,
			Section2,
			Section4,
			Section5,
			Section6,
			Section7,
			Section8,
			Section9,
			Section10,
			Section11,
			Section12,
			Section13,
			Section14,
			Section15,
			Section16,
			Section17,
			Section18,
			FooterTbf
		},
		methods: {
			
		}
	}
</script>