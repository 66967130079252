<template>
	<div class="tbf-digital" data-scrollbar>
		<div class="actions-fixed">
			<div class="logo top-left" @click="$route.name == 'tbf-digital' ? scrollToTop() : $router.push({ name: 'tbf-digital' })"><logo /></div>
			<div class="menu top-right" @click="openMenu"><menu-bars /></div>
			<div class="share bottom-left">
				<div class="share-btn"><share /></div>
				<div class="list-shares">
					<a href="https://www.facebook.com/thebasicfundamentals" target="_blank"><facebook /></a>
					<a href="https://www.instagram.com/thebasicfundamentals/" target="_blank"><instagram /></a>
					<a href="https://linkedin.com/company/thebasicfundamentals" target="_blank"><linkedin /></a>
				</div>
			</div>
			<div id="arrowBottomRight" class="bottom-right back" @click="scrollToTop()"><span>Scroll mai jos</span><icon-arrow class="icon-arrow" /></div>
		</div>

		<popup-cookies id="popupCookies" v-if="viewPopupCookie" @response_cookie="saveResponseCookie"/>

		<div v-if="showOverlay" @click="closeMenu" class="overlay-menu"></div>
		<modal-menu @close_menu="closeMenu"/>

		<div id="appdigital">
			<desktop v-if="$resize && $mq.above(960)"/>
			<mobile v-else/>
		</div>
	</div>
</template>

<script>
	import Logo from '../../assets/landing/Logo'
	import MenuBars from '../../assets/landing/MenuBars'
	import Share from '../../assets/landing/Share'
	import IconArrow from '../../assets/landing/Arrow'

	import Facebook from '../../assets/landing/logos/Facebook'
	import Instagram from '../../assets/landing/logos/Instagram'
	import Linkedin from '../../assets/landing/logos/Linkedin'

	import PopupCookies from '../General/PopupCookies'
	import ModalMenu from '../General/ModalMenu'

	import Desktop from './HomeDesktopVersion'
	import Mobile from './HomeMobileVersion'
	export default {
		data() {
			return {
				viewPopupCookie: false,
				showOverlay: false	
			};
		},
		components: {
			Logo,
			MenuBars,
			Share,
			IconArrow,
			PopupCookies,
			ModalMenu,
			Facebook,
			Instagram,
			Linkedin,
			Desktop,
			Mobile
		},
		mounted(){
			this.scroll()
			
			if(!this.$cookies.get('acceptCookies')){
				this.viewPopupCookie = true
				setTimeout(() => {
					document.getElementById('popupCookies').classList.add('active')
				}, 4000)
			}

		},
		methods: {
			scroll () {
				window.onscroll = () => {
					let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + (window.innerHeight * 2) >= document.documentElement.offsetHeight 

					if(bottomOfWindow){
						document.querySelector('#arrowBottomRight').classList.remove('back')
					}else{
						document.querySelector('#arrowBottomRight').classList.add('back')
					}
				}
			},
			saveResponseCookie(value){
				document.getElementById('popupCookies').classList.remove('active')
				setTimeout(() => {
					this.$cookies.set('acceptCookies', true, { expires: '1M' })
					this.viewPopupCookie = false
				}, 200)
			},
			scrollToTop(){
				if (!document.getElementById('arrowBottomRight').classList.contains('back')) {
					$("html, body").animate({ scrollTop: 0 }, "slow");
				}
			},
			openMenu(){
				this.showOverlay = true
				setTimeout(() => {
					document.querySelector('.overlay-menu').classList.add('show')
					document.querySelector('.menu-tbf').classList.add('active')
				}, 0)
			},
			closeMenu(){
				document.querySelector('.menu-tbf').classList.remove('active')
				setTimeout(() => {
					document.querySelector('.overlay-menu').classList.remove('show')
					setTimeout(() => {
						this.showOverlay = false;
					}, 200);
				}, 200);
			}
		}
	}
</script>

<style lang="scss">
    @import "../../styles/pages/landing.scss";
</style>
