<template>
	<div class="section-9">
		<div class="container-section">
			<div class="box-section">
				<div class="content-section">
					<svg-section-9 />

					<div class="title">2 Metode de<br/>măsurare a progresului</div>
					<div class="description">Obiectivele fără termene limită și metode clare de măsurare sunt visuri deșarte.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection9 from '../../assets/landing/SvgSection9'

	export default {
		data() {
			return {

			}
		},
		components: {
			SvgSection9
		},
		mounted(){
		}
	}
</script>