<template>
	<div class="footer-tbf">
		<div class="container-section">
			<div class="content-top">
				<div class="title">Începe acum să folosești<br />Platforma TBF Digital</div>
				<div class="description">Simplifică managementul, rămâi în control și scalează rapid.</div>
				<button  @click="$router.push({ name: 'pricing' })" class="try-free-btn black">Alege abonamentul potrivit <icon-arrow class="icon-arrow right"/></button>
			</div>
			<div class="content-bottom">
				<div class="columns-footer">
					<div class="column-footer">
						<div class="mail"><a href="mailto:digital@tbf.ro">digital@tbf.ro</a></div>
						<div class="address">Copyright © 2022 TBF Digital<br/>Bulevardul Pipera 1-IA</div>
						<div class="social">
							<a href="https://www.facebook.com/thebasicfundamentals" target="_blank"><img src="/build/images/logo-fb.svg"></a>
							<a href="https://www.instagram.com/thebasicfundamentals/" target="_blank"><img src="/build/images/logo-instagram.svg"></a>
							<a href="https://linkedin.com/company/thebasicfundamentals" target="_blank"><img src="/build/images/logo-linkedin.png"></a>
						</div>
					</div>
					<div class="column-footer">
						<div class="category" v-for="(fl,index) in links.col1" v-bind:key="index"><a :href="fl['link']" target="_blank">{{ fl['name'] }}</a></div>
					</div>
					<div class="column-footer">
						<div class="category" v-for="(fl,index) in links.col2" v-bind:key="index"><a :href="fl['link']" target="_blank">{{ fl['name'] }}</a></div>
					</div>
					<div class="column-footer">
						<div class="category" v-for="(fl,index) in links.col3" v-bind:key="index"><a :href="fl['link']" target="_blank">{{ fl['name'] }}</a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../Icons/Arrow'
	import { gsap } from "gsap";
	import axios from 'axios';
	export default {
		data() {
			return {
				links: {}
			};
		},
		components: {
			IconArrow
		},
		mounted(){
			this.getLinks();
			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.footer-tbf',
					start: "top 5%",
					end: "bottom 5%",
					onToggle: self => self.isActive ? document.querySelector('.actions-fixed').classList.add('active-top-19') : document.querySelector('.actions-fixed').classList.remove('active-top-19'),
					scrub: true
				},
			})

			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.footer-tbf',
					start: "top 95%",
					end: "bottom 95%",
					onToggle: self => self.isActive ? document.querySelector('.actions-fixed').classList.add('active-bottom-19') : document.querySelector('.actions-fixed').classList.remove('active-bottom-19'),
					scrub: true
				}
			})
		},
		methods:{
			getLinks(){
				axios.get("https://backend.tbf.ro/api/digital-links").then(res => {
					this.links = res.data.footer;
				})
			},
			goTo(params){
				let routeData = this.$router.resolve(params);
				window.open(routeData.href, '_blank');
			}
		}
	}
</script>